import * as XLSX from 'xlsx';

export const readFileAsync = (file) =>{
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
}

export const readExcel = (bufferArray) => {
    const wb = XLSX.read(bufferArray, { type: "buffer" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]

    const data = XLSX.utils.sheet_to_json(ws)
    return data
}

export const exportStudentData = (tableData, type, fun, dl) => {

  const data = [];

  //Make readable Excel headers
  for(let i = 0; i < tableData.length; i++) {
    let row = tableData[i];

    data.push({
      'Roll Number': row['rowKey'],
      'Student Name': row['name'],
      'Attendance %': Math.round(row['attendance']),
      'Quiz Marks': Math.round(row['quiz']),
      'Assignment Score %':  Math.round(row['assignment']),
      'Gender': row['gender']
    })
  }

  const myWorkSheet = XLSX.utils.json_to_sheet(data);
  const today = new Date().toISOString()
  const myWorkBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "student_stat");

  return dl ?
      XLSX.write(myWorkBook, { bookType: type, bookSST: true, type: 'base64' }) :
      XLSX.writeFile(myWorkBook, fun || ('student-data-' + today + '.' + (type || 'xlsx')));
}

export const exportProjectData = (exportDate, reportPhase, type, fun) => {

  const myWorkSheet = XLSX.utils.json_to_sheet(exportDate);
  const today = new Date().toISOString()
  const myWorkBook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "project_stats");

  return XLSX.writeFile(myWorkBook, fun || (`project-data-review${reportPhase}-` + today + '.' + (type || 'xlsx')));
}
