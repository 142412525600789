<template>
    <div>
        <v-row>
            <v-col
                class="d-flex"
                cols="12"
                sm="3"
            >
                <v-select
                    clearable
                    :items="courseSubjects"
                    return-object
                    item-value="id"
                    item-text="desc"
                    label="Select Module (Attendance)"
                    solo
                    v-model="selectedCourse"
                    @change="reInitializeData"
                    @click:clear="removeCourse"
                >
                </v-select>
            </v-col>

            <v-col
                class="d-flex"
                cols="12"
                sm="3"
            >
                <v-select
                    clearable
                    :items="quizes"
                    return-object
                    item-value="quiz_id"
                    item-text="quiz_quizName"
                    label="Select Quiz"
                    solo
                    @change="reInitializeData"
                    @click:clear="removeQuiz"
                    v-model="selectedQuiz"
                >
                </v-select>
            </v-col>

            <v-col
                class="d-flex"
                cols="12"
                sm="3"
            >
                <v-select
                    clearable
                    :items="assignments"
                    return-object
                    item-value="assignment_id"
                    item-text="assignment_assignmentName"
                    label="Select Assignment"
                    solo
                    @change="reInitializeData"
                    @click:clear="removeAssignment"
                    v-model="selectedAssignment"
                >
                </v-select>
            </v-col>

            <v-col
                class="d-flex"
                cols="12"
                sm="3"
            >
                <v-btn
                    color="primary"
                    block
                    x-large
                    @click="exportData"
                >
                export data
                </v-btn>
            </v-col>

        </v-row>
        <div class="student-table-container">
            <div class="table-list">
                <ve-table
                    id="student-data-container"
                    ref="tableRef"
                    fixed-header
                    border-y
                    max-height="calc(100vh - 160px)"
                    :scroll-width="tableScrollWdith"
                    :sort-option="sortOption"
                    :virtual-scroll-option="virtualScrollOption"
                    :columns="columns"
                    :table-data="tableData"
                    row-key-field-name="rowKey"
                    :cell-style-option="cellStyleOption"
                    :expand-option="expandOption"
                    :row-style-option="rowStyleOption"
                />
            </div>
        </div>
    </div>
</template>

<script>

import { exportStudentData } from '../../utils/xlsUtils'

export default {
    name: "StudentTable",
    components: {
        /* Footer */
    },
    data() {
        return {
            selectedCourse: {},
            selectedQuiz: {},
            selectedAssignment: {},

            // edit option 
            editOption: {
                // cell value change
                cellValueChange: ({ row, column }) => {
                    console.log("cellValueChange row::", row);
                    console.log("cellValueChange column::", column);
                },
            },
            switchActiveColor: "#1890ff",
            switchInactiveColor: "rgba(0,0,0,.25)",

            enableColumnFixed: true,
            enableExpand: false,

            // ---------------table options---------------
            sourceData: [],
            tableData: [],
            startRowIndex: 0,
            // filter condition
            filterConditions: [],
            cellStyleOption: {
                bodyCellClass: ({ row, column, rowIndex }) => {
                    if (column.field === "attendance") {
                        return "table-body-cell-proficiency";
                    }
                    if (column.field === "assignment") {
                        return "table-body-cell-proficiency";
                    }
                    if (column.field === "quiz") {
                        return "table-body-cell-proficiency";
                    }
                },
            },
            virtualScrollOption: {
                enable: false,
            },
            rowStyleOption: {
                stripe: true,
            },
            sortOption: {
                sortChange: (params) => {
                    this.sortChange(params);
                },
            },
            expandOption: {
                render: ({ row, column, rowIndex }, h) => {
                    return (
                        <p>
                            Hello everyone, My name is{" "}
                            <span style="font-weight:bold;">{row.name}</span>,
                            I'm a {row.profession}. And I'm living in{" "}
                            {row.address}.
                        </p>
                    );
                },
            },
        };
    },
    computed: {
        courseSubjects(){    
            let courseSubjects = [];
            
            for(let i = 0; i < this.$store.getters.getCourseSubjects.length; i++){
                let courseSubject = {}
                let current = this.$store.getters.getCourseSubjects[i]

                courseSubject['desc'] = `[${current.subject.subjectName}] ${current.subject.subjectDescription}`
                courseSubject['id'] = current.id

                courseSubjects.push(courseSubject)
            }
            
            return courseSubjects
        },
        quizes(){
            return this.$store.getters.getQuizStat
        },
        assignments(){
            return this.$store.getters.getAssignmentStat
        },
        stats(){
          return this.$store.getters.getStudentStat
        },
        // tableScrollWidth
        tableScrollWdith() {
            let scrollWidth = 0;

            const { columns } = this;

            if (columns.length) {
                columns.forEach((item) => {
                    if (typeof item.width === "number") {
                        scrollWidth += item.width;
                    }
                });
            }

            return scrollWidth;
        },

        // columns
        columns() {
            let columns = [];

            if (this.enableExpand) {
                columns.push({
                    field: "expand",
                    key: "expand",
                    title: "",
                    width: 100,
                    fixed: this.enableColumnFixed ? "left" : "",
                    type: "expand",
                });
            }

            columns = columns.concat([
                {
                    title: "Basic Info",
                    fixed: this.enableColumnFixed ? "left" : "",
                    width: 300,
                    children: [
                        {
                          field: "rowKey",
                          key: "rowKey",
                          title: "Roll No.",
                          width: 100,
                          fixed: this.enableColumnFixed ? "left" : "",
                          renderBodyCell: this.renderRowIndex,
                        },
                        {
                            field: "name",
                            key: "name",
                            title: "Name",
                            width: 200,
                            align: "left",
                        },
                    ],
                },
                {
                    title: "Performance",
                    align: "center",
                    width: 800,
                    children: [                       
                        {
                            field: "attendance",
                            key: "attendance",
                            title: "Attendance",
                            width: 300,
                            sortBy: "",
                            edit: true,
                            renderBodyCell: (
                                { row, column, rowIndex },
                                h,
                            ) => {
                                const cellData = Math.round(row[column.field]);

                                const colorClass =
                                    cellData >= 75
                                        ? "attendance-blue"
                                        : cellData >= 65
                                        ? "attendance-orange"
                                        : "attendance-red";

                                return (
                                    <div class="proficiency-span-container">
                                        <span
                                            class={
                                                "proficiency-span " +
                                                colorClass
                                            }
                                            style={
                                                "width:" +
                                                cellData +
                                                "%;"
                                            }
                                        >
                                            {cellData}%
                                        </span>
                                    </div>
                                );
                            },
                        },
                        {
                            field: "assignment",
                            key: "assignment",
                            title: "Assignment",
                            width: 300,
                            align: "left",
                            sortBy: "",
                            edit: true,
                            renderBodyCell: (
                                { row, column, rowIndex },
                                h,
                            ) => {
                                const cellData = Math.round(row[column.field]);

                                const colorClass =
                                    cellData > 75
                                        ? "attendance-blue"
                                        : cellData > 65
                                        ? "attendance-orange"
                                        : "attendance-red";

                                return (
                                    <div class="proficiency-span-container">
                                        <span
                                            class={
                                                "proficiency-span " +
                                                colorClass
                                            }
                                            style={
                                                "width:" +
                                                cellData +
                                                "%;"
                                            }
                                        >
                                            {cellData}%
                                        </span>
                                    </div>
                                );
                            },
                        },
                        {
                            field: "quiz",
                            key: "quiz",
                            title: "Quiz",
                            width: 300,
                            align: "left",
                            sortBy: "",
                            edit: true,
                            renderBodyCell: (
                                { row, column, rowIndex },
                                h,
                            ) => {
                                const cellData = Math.round(row[column.field]);

                                const colorClass =
                                    cellData > 15
                                        ? "attendance-blue"
                                        : cellData > 10
                                        ? "attendance-orange"
                                        : "attendance-red";

                                return (
                                    <div class="proficiency-span-container">
                                        <span
                                            class={
                                                "proficiency-span " +
                                                colorClass
                                            }
                                            style={
                                                "width:" +
                                                cellData * 5 +
                                                "%;"
                                            }
                                        >
                                            {cellData}
                                        </span>
                                    </div>
                                );
                            },
                        },
                    ],
                },
                {
                    field: "gender",
                    key: "gender",
                    title: "Gender",
                    width: 100,
                    fixed: this.enableColumnFixed ? "right" : "",
                    align: "left",
                    // filter
                    filter: {
                        filterList: [
                            { value: "Male", label: "Male", selected: false },
                            { value: "Female", label: "Female", selected: false }
                        ],
                        // filter confirm hook
                        filterConfirm: (filterList) => {
                            const values = filterList
                                .filter((x) => x.selected)
                                .map((x) => x.value);
                            this.searchByNameField(values);
                        },
                        // filter reset hook
                        filterReset: (filterList) => {
                            this.searchByNameField([]);
                        },
                    },
                    renderBodyCell: ({ row, column, rowIndex }, h) => {
                        const cellData = row[column.field];
                        return (
                            <span
                                class="status-span"
                            >
                                {cellData}
                            </span>
                        );
                    },
                },
            ]);

            return columns;
        },
    },
    methods: {
        exportData(){
            exportStudentData(this.tableData)
        },
        renderRowIndex({ row, column, rowIndex }) {
            return <span>{row.rowKey}</span>;
        },

        // search by name field
        searchByNameField(values) {
            this.filterConditions = values;
            this.filter();
            // scroll to top
            this.$refs["tableRef"].scrollTo({ top: 0 });
        },

        //
        filter() {
            const values = this.filterConditions;
            this.tableData = this.sourceData
                .slice(0)
                .filter(
                    (x) => values.length === 0 || values.includes(x.gender),
                );
        },

        // sort change
        sortChange(params) {
            const sortFileld0 = 'quiz'
            const sortFileld1 = 'assignment'
            const sortFileld2 = "attendance";
            
            if (params[sortFileld1] || params[sortFileld2] || params[sortFileld0]) {
                this.tableData.sort((a, b) => {
                    if (params[sortFileld0]) {
                        if (params[sortFileld0] === "asc") {
                            return a[sortFileld0] - b[sortFileld0];
                        } else if (params[sortFileld0] === "desc") {
                            return b[sortFileld0] - a[sortFileld0];
                        }
                    } 
                    else if (params[sortFileld1]) {
                        if (params[sortFileld1] === "asc") {
                            return a[sortFileld1] - b[sortFileld1];
                        } else if (params[sortFileld1] === "desc") {
                            return b[sortFileld1] - a[sortFileld1];
                        }
                    } else if (params[sortFileld2]) {
                        if (params[sortFileld2] === "asc") {
                            return a[sortFileld2] - b[sortFileld2];
                        } else if (params[sortFileld2] === "desc") {
                            return b[sortFileld2] - a[sortFileld2];
                        }
                    }
                });
            } else {
                this.resetTableData();
            }

            // scroll to top
            this.$refs["tableRef"].scrollTo({ top: 0 });
        },

        // reset table data
        resetTableData() {
            this.tableData = this.sourceData.slice(0);
            this.filter();
        },

        // data row change
        dataRowChange() {
            setTimeout(() => {
                this.initSourceData();
                // scroll to top
                this.$refs["tableRef"].scrollTo({ top: 0 });
            });
        },

        //Initialize table data
        initSourceData(sourceData) {
            let data = [];

            for (let i = 0; i < sourceData.length; i++) {
                data.push({
                    rowKey: sourceData[i].rollNumber,
                    name: sourceData[i].name,
                    quiz: sourceData[i].quizScore ? parseFloat(sourceData[i].quizScore): 0,
                    attendance: sourceData[i].attendance ? sourceData[i].attendance : 0,
                    assignment: sourceData[i].assignmentScore ? parseFloat(sourceData[i].assignmentScore): 0,
                    gender: sourceData[i].gender,
                });
            }

            this.sourceData = data;
            this.resetTableData();
        },
        removeCourse() {
            this.selectedCourse = {}
            this.reInitializeData()
        },
        removeQuiz () {
            this.selectedQuiz = {}
            this.reInitializeData()
        },
        removeAssignment() {
            this.selectedAssignment = {}
            this.reInitializeData()
        },
        reInitializeData(){
           const params = {}
           
           if(this.selectedCourse){
               params['courseSubjectId'] = this.selectedCourse.id
           }

           if (this.selectedQuiz){
               params['quizId'] = this.selectedQuiz.quiz_id
           }

           if (this.selectedAssignment){
               params['assignmentId'] = this.selectedAssignment.assignment_id
           }

           this.$store.dispatch('fetchStudentStat', params)
        }
    },
    watch:{
      stats(n, o){
        this.initSourceData(n);
      }
    },
    created() {
    },
    mounted() {
      this.$store.dispatch('fetchStudentStat')
      this.initSourceData(this.stats)
    },
    destroyed() {
      //this.switchThemeMix("theme-default");
    },
};
</script>

<style>

.student-table-container {
	 display: flex;
	 flex-direction: column;
	 background: #fff;
}

.student-table-container .operation {
	 margin: 10px 0;
}

.student-table-container .operation .operation-item {
	 height: 50px;
	 line-height: 50px;
}

.student-table-container .table-list .table-body-cell-proficiency {
	 padding: 0 !important;
}

.student-table-container .table-list .proficiency-span-container {
	 height: 100%;
	 text-align: left;
}

.student-table-container .table-list .proficiency-span-container .proficiency-span {
	 height: 100%;
	 display: inline-flex;
	 align-items: center;
	 padding-left: 10px;
	 font-weight: bold;
	 color: #555;
}

.student-table-container .table-list .proficiency-span-container .proficiency-span.attendance-blue {
	 background-color: RGBA(24,144,255,0.7);
}

.student-table-container .table-list .proficiency-span-container .proficiency-span.attendance-orange {
	 background-color: RGBA(255,179,0,0.7);
}

.student-table-container .table-list .proficiency-span-container .proficiency-span.attendance-red {
	 background-color: RGBA(244,93,81,0.7);
}


.ve-radio-label{
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

.ve-dropdown-operation-item{
  display: inline-block;
	margin-right: 5px;
	padding: 3px 8px;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

</style>
